<template>
  <div class="checkout">
    <back-button
      v-if="hasPedidoAberto && hasItemsInCart"
      route="/catalogo"
      text="Continuar comprando"
    ></back-button>
    <div class="c-body">
      <div class="cb-content">
        <template v-if="hasPedidoAberto && hasItemsInCart">
          <keep-alive
            ><router-view
              :key="$route.fullPath"
              class="cb-router-view"
            ></router-view
          ></keep-alive>
        </template>
        <template v-else-if="orderPlacedData.situacao == 1 && hasPedidoAberto">
          <order-complete :order-placed-data="orderPlacedData"></order-complete>
        </template>
        <template v-else>
          <cart-empty></cart-empty>
        </template>
      </div>
      <div v-if="hasPedidoAberto && hasItemsInCart" class="cb-sidebar">
        <order-overview
          @orderPlacedData="orderPlacedData = $event"
        ></order-overview>
      </div>
    </div>
  </div>
</template>

<script>
import OrderOverview from "./OrderOverview";
import CartMixin from "@/app/checkout/cart/CartMixin";

import CartEmpty from "./CartEmpty";

import OrderComplete from "./OrderComplete";

import BackButton from "@/app/checkout/components/BackButton";

import bus from "@/utils/events/bus";

export default {
  name: "Checkout",
  components: {
    OrderOverview,
    CartEmpty,
    OrderComplete,
    BackButton,
  },
  mixins: [CartMixin],
  data() {
    return {
      orderPlacedData: {},
    };
  },
  computed: {},
  watch: {
    "$route.name": function (newValue) {
      if (newValue === "checkout") {
        this.redirectToNextStep();
      }
    },
    paymentMethod(newValue, oldValue) {
      if (newValue != oldValue) {
        this.redirectToNextStep();
      }
    },
  },
  created() {},
  mounted() {
    this.redirectToNextStep();

    if (
      this.hasPedidoAberto &&
      this.paymentMethod === this.PAYMENT_METHODS.CREDIT_CARD
    ) {
      this.callAntiFraud();
    }
  },
  beforeDestroy() {
    this.callAntiFraud(false);
    // console.log("ill destroy");
    this.$store.dispatch("saveCards", []); // Remove os cartões salvos
  },
  methods: {
    redirectToNextStep() {
      switch (this.paymentMethod) {
        case this.PAYMENT_METHODS.CREDIT_CARD:
          this.$router.push({
            name: "checkoutPayment",
            query: { ...this.$route.query },
          });
          break;
        default:
          this.$router.push({
            name: "checkoutReview",
            query: { ...this.$route.query },
          });
          break;
      }
    },
    callAntiFraud(addItem = true) {
      // const orgId =
       // process.env.NODE_ENV === "production" ? "k8vif92e" : "1snn5n9w";
      // const fpUrl = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${this.clientSession}`;

      const session =
        process.env.NODE_ENV === "production" ? this.clientSession : "test-123";
      const fpUrl = `https://api.pre.globalgetnet.com/dpm/digital-platform/antifraud/afdf.js?session=${session}&country=BR`;

      const exists = document.getElementsByTagName("script");

      if (exists) {
        const item = Array.from(exists).find(
          (x) => x.getAttribute("src") === fpUrl
        );

        if (addItem) {
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", fpUrl);

          document.head.appendChild(recaptchaScript);
        } else if (item) {
          document.head.removeChild(item);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.checkout {
  background: #eee;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .c-body {
    flex: 1 0 100%;
    max-width: 1150px;
    background: white;
    margin: 20px auto;
    width: 100%;
    border: 1px solid #e5e5e5;
    min-height: calc(100vh - 320px);
    padding: 20px;
    display: flex;
    // overflow: hidden;
    border-radius: 5px;
    flex-wrap: wrap-reverse;

    .cb-content {
      flex: 1 0 66.5%;
      padding-right: 15px;
      display: flex;
      width: 100%;

      .cb-router-view {
        display: flex;
        flex: 1;
        width: 100%;
      }
    }
    .cb-sidebar {
      flex: 1 0 33%;
      min-width: 200px;
      border-left: 1px solid #e5e5e5;
      padding-left: 15px;
    }
  }
}

// @media (max-width: 1050px) {
//   .checkout {
//     .c-body {
//       .cb-content {
//         flex: 1 0 65%;
//       }
//       .cb-sidebar {
//         flex: 1 0 35%;
//       }
//     }
//   }
// }

@media (max-width: 720px) {
  .checkout {
    padding: 0;

    .c-body {
      border-radius: 0;
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 633px) {
  .c-body {
    .cb-sidebar {
      padding: 0 !important;
      border: none !important;
    }
    .cb-content {
      margin-top: 20px;
      padding: 0 !important;
    }
  }
}
</style>
